import React from 'react'
import { Paper } from '@mui/material'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import PersonRounded from '@mui/icons-material/PersonRounded'
import Quote from '@mui/icons-material/FormatQuoteRounded'
import { Testimonial } from '../types/Testimonial'

const StyledTestimonial = styled(Paper)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 1.1px;
  padding: 40px 24px 48px;
  position: relative;

  p {
    opacity: 0.8;
    text-align: left;
  }
`

const quoteIcon = css`
  opacity: 0.8;
  position: absolute;
  left: 12px;
  top: 12px;
`

const PersonWrapper = styled.div`
  align-items: center;
  bottom: 12px;
  display: flex;
  justify-content: flex-end;
  opacity: 0.8;
  position: absolute;
  right: 24px;
`

const PersonName = styled.span`
  font-size: 20px;
  margin-right: 16px;
`

const PersonCircle = styled.div`
  background: #e0e0e0;
  border-radius: 50%;
  color: #818181;
  font-size: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
`

function TestimonialCard({ person, quote }: Testimonial) {
  return (
    <StyledTestimonial>
      <Quote css={quoteIcon} />
      <PersonWrapper>
        <PersonName>{person.firstName}</PersonName>
        <PersonCircle>
          <PersonRounded />
        </PersonCircle>
      </PersonWrapper>
      <div
        dangerouslySetInnerHTML={{ __html: quote.childMarkdownRemark.html }}
      />
    </StyledTestimonial>
  )
}

export default TestimonialCard
